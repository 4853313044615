import 'alpinejs'
// import 'taggle'

window.Taggle = require('taggle')

// function tagLead() {
//     return {
//         feedback: '',
//         taggles: [],
//         init() {
//             new Taggle('tags', {
//                 onTagAdd: function(event, tag) {
//                     feedback = 'You just added ' + tag;
//                 },
//                 onTagRemove: function(event, tag) {
//                     feedback = 'You just removed ' + tag;
//                 }
//             })
//         }
//     }
// }

//const Tagify = window.Tagify = require('@yaireo/tagify');

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
